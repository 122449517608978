import { render, staticRenderFns } from "./Product.vue?vue&type=template&id=0fe9927a&"
import script from "./Product.vue?vue&type=script&lang=js&"
export * from "./Product.vue?vue&type=script&lang=js&"
import style0 from "./Product.vue?vue&type=style&index=0&id=0fe9927a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Breadcrumbs: require('/app/components/breadcrumbs.vue').default,ShareButtons: require('/app/components/ShareButtons.vue').default,ProductGallery: require('/app/components/Product/Gallery.vue').default,ProductOfferCountDown: require('/app/components/Product/OfferCountDown.vue').default,StickySidebar: require('/app/components/StickySidebar.vue').default,ProductRating: require('/app/components/Product/Rating.vue').default})
