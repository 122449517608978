
export default {
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			carouselModel: 0,
		}
	},
}
