
export default {
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	computed: {
		fullPath() {
			const domain = window.location.origin
			return `${domain}${this.localePath({
				name: "product",
				params: {
					productSlug: this.product.slug,
					varianceSlug: this.product.variance?.slug,
				},
			})}`
		},
		facebookShareUrl() {
			return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.fullPath)}`
		},
		whatsappShareUrl() {
			return `https://api.whatsapp.com/send?text=${encodeURIComponent(this.fullPath)}`
		},
		twitterShareUrl() {
			return `https://x.com/intent/tweet?text=${encodeURIComponent(this.fullPath)}`
		},
		emailShareUrl() {
			const subject = this.product.name
			const body = encodeURIComponent(`${this.product.name} \n\nCheck it out here: ${this.fullPath}`)
			return `mailto:?subject=${subject}&body=${body}`
		},
	},
	methods: {
		shareOnFacebook() {
			window.open(this.facebookShareUrl, "_blank")
		},
		shareOnInstagram() {
			alert("Instagram doesn't support direct link sharing. You can manually share the product.")
		},
		shareOnWhatsApp() {
			window.open(this.whatsappShareUrl, "_blank")
		},
		shareOnTwitter() {
			window.open(this.twitterShareUrl, "_blank")
		},
		shareOnEmail() {
			window.open(this.emailShareUrl, "_self")
		},
	},
}
