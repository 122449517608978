
export default {
	props: {
		items: {
			type: Array,
			default: null,
		},
	},

	data() {
		return {}
	},
}
