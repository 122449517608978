
// create component that convert date string to days, hours, minutes, seconds and starts counting down on mounted
export default {
	props: {
		date: {
			type: String,
			default: null,
		},
	},
}
