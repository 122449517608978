
export default {
	fetchOnServer: false,
	props: {
		id: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			ratings: {},
			reviewForm: {
				rating: 0,
				review: "",
			},
		}
	},
	async fetch() {
		await this.$axios.$get(`/v1/ratings/${this.id}`).then((res) => {
			this.ratings = res
		})
	},
	methods: {
		submitReview() {
			if (!this.$auth.loggedIn) {
				this.$nuxt.$emit("auth.show", "login")
			}

			if (!this.$refs.form.validate()) { return }

			this.$axios
				.post(`/v1/products/${this.id}/rating`, this.reviewForm)
				.then((resp) => {
					this.$toast.success(`${this.$t("common.review-submit-on-product-success")}`)
					this.reviewForm = {
						rating: 0,
						review: "",
					}
				})
				.catch((e) => {
					console.log("error in POST:productSlug/rating", e)
					this.$toast.warning(`${this.$t("common.review-submit-on-product-failed")}`)
				})
				.finally(() => {
					this.$refs.form.reset()
					this.$refs.reviewForm.reset()
					this.reviewForm = {
						rating: 0,
						review: "",
					}
				})
		},
	},
}
