export default {
	methods: {
		generateOptionSlugFromAttributeOptionsArray(attributeOptionsArray, locale = null) {
			console.log(
				"🚀 ~ file: product.js:4 ~ generateOptionSlugFromAttributeOptionsArray ~ attributeOptionsArray:",
				attributeOptionsArray,
			)
			const { toKebabCase } = require("js-convert-case")

			const newArray = attributeOptionsArray.map((attributeOption) => {
				return toKebabCase(
					`${attributeOption.prefix}-${
						locale ? attributeOption.value[locale] : attributeOption.value
					}-${attributeOption.suffix}-`,
				)
			})

			return toKebabCase(newArray.join("-"))
		},
	},
}
